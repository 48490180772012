@import "./variables";

* {
    box-sizing: border-box;
}

::placeholder {
    color: #666;
}

::selection {
    background-color: $accent;
    color: #fff;
}

body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: 1.65;
    font-family: $default-font;
    overflow-x: hidden;
    color: $text;
    background-color: $bg;
    padding-top: 0;
    margin: 0;

    input,
    textarea {
        border: #666 1px solid;
        outline: none;

        &:focus:required:invalid {
            border-color: red;
        }

        &:required:valid {
            border-color: green;
        }
    }
}

.scroll-hidden {
    overflow-y: hidden;
}

.container {
    padding: 0 15px;
}

a {
    text-decoration: none;
}

.btn {
    padding: 12px 24px;
    border-radius: 8px;
    background-color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: all .3s ease-in-out;

    svg {
        fill: #fff;
        width: 24px;
        height: 24px;
    }

    &.white {
        background-color: #fff;
        color: $accent;

        svg {
            fill: $accent;
        }

        &:hover {
            background-color: darken(#fff, 10%);
            color: $accent;
        }
    }

    &.anim {
        svg {
            transition: all .3s ease-in-out;
        }

        &:hover {
            svg {
                transform: translate(3px, -3px)
            }
        }
    }

    &:hover {
        background-color: darken($accent, 10%);
        color: #fff;
    }
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.counter {
    display: inline-block;
    background-color: $accent;
    padding: 0 5px;
    color: #fff;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 700;
}

.wrap {
    max-width: 428px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.accent {
    font-weight: 700;
    color: $accent;
}